import React from 'react'
import { linkResolver } from './linkResolver'

export const repositoryConfigs = [
  {
    repositoryName: process.env.GATSBY_PRISMIC_MAIN_REPOSITORY_NAME,
    linkResolver,
    componentResolver: {
      blogpost: React.lazy(() => import('../templates/blogpost')),
      case_study: React.lazy(() => import('../templates/caseStudy')),
      page: React.lazy(() => import('../templates/page')),
      team_member: React.lazy(() => import('../templates/teamMember')),
      private_page: React.lazy(() => import('../templates/privatePage')),
      vacancy: React.lazy(() => import('../templates/vacancy'))
    }
  }
]
